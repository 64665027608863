@use "sass:meta" as ---6r6i8kqj18t;header {
  @include breakpoint(medium) {
    padding-top: rem-calc(20);
  }

  .top-bar {
    background-color: #fff;

    @include breakpoint(small only) {
      justify-content: center;
    }

    @include breakpoint(medium) {
      padding: .5rem 0;
    }

    ul {
      background-color: #fff;
    }
  }

  .logo {
    width: rem-calc(150);
    min-height: rem-calc(75);
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a {
    padding-right: .9rem;

    &::after {
      display: none;
    }
  }

  .menu {
    @include breakpoint(small only) {
      justify-content: center;
      margin: rem-calc(10 0 20);
    }

    @include breakpoint(medium) {
      justify-content: space-between;
    }

    .dropdown {
      display: flex;
      margin: 0;

      @include breakpoint(small only) {
        justify-content: space-between;
      }

      &_item {
        text-transform: uppercase;
        font-size: rem-calc(14);
        align-self: center;
        position: relative;

        a {
          color: #000;
          background-color: #fff;

          @include breakpoint(medium) {
            padding: .7rem .9rem;
            font-size: 1rem;
          }
        }

        &:hover::after {
          content: '';
          display: block;
          position: absolute;
          top: rem-calc(32);
          width: rem-calc(50);
          height: rem-calc(3);
          margin-left: rem-calc(16);
          background-color: #f00;
        }

        &:last-child {
          @include breakpoint(medium) {
            a {
              padding-right: 0;
            }
          }
        }
      }

      .submenu {
        left: 0;
        margin: 0;
        top: 140%;
        border: none;

        &_item > a {
          text-transform: none;
          color: #848484;

          &:hover {
            background-color: #e0e3e3;
          }
        }
      }
    }

    .dropdown,
    .submenu {
      list-style: none;
    }
  }

  .social {
    &::before {
      content: '';
      display: block;
      background-color: #f7f7f7;
      position: absolute;
      width: 40vw;
      min-height: rem-calc(44);
      z-index: -1;

      @include breakpoint (small only) {
        display: none;
      }
    }

    &_wrapper {
      background-color: #f7f7f7;
    }

    .partner {
      font-size: rem-calc(12);
    }

    &_icons {
      display: flex;
      margin: 0;

      li {
        &:not(:last-child) {
          margin-right: rem-calc(14);
        }

        &:first-child {
          margin-left: rem-calc(10);
        }
      }

      span {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
      }

      &.icon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem-calc(26);
        width: rem-calc(26);
        height: rem-calc(45);

        &.vk {
          background-image: url('./img/logo-social/vk.svg');
        }

        &.instagram {
          background-image: url('./img/logo-social/instagram.svg');
        }

        &.facebook {
          background-image: url('./img/logo-social/facebook.svg');
        }

        &.telegram {
          background-image: url('./img/logo-social/vk.svg');
        }
      }
    }
  }

  .partner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_text {
      display: flex;
      justify-content: flex-end;
      color: #a4a3a3;
      padding: rem-calc(13);
      flex-grow: 1;

      @include breakpoint(small only) {
        background-color: #fff;
        padding: rem-calc(13 13 13 0);
      }

      @include breakpoint(medium) {
        background-color: #f7f7f7;
      }
    }

    &_logo {
      display: flex;
      flex-shrink: 1;

      svg {
        padding: rem-calc(10 0 10 10);
        width: rem-calc(100);
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1672");