@use "sass:meta" as ---6r6i8kqj18t;footer {
  margin-top: rem-calc(100);
  background-color: #e6e6e6;

  .wrap {
    padding: rem-calc(20 0);

    .logo {
      @include breakpoint(small only) {
        display: flex;
        justify-content: center;
      }

      img {
        width: rem-calc(180);
      }
    }
  }

  .menu {
    text-transform: uppercase;

    @include breakpoint(small only) {
      display: flex;
      justify-content: center;
      margin-top: rem-calc(20);
    }

    @include breakpoint(medium only) {
      margin-left: rem-calc(20);
    }

    li {
      a {
        font-weight: 500;
        font-size: rem-calc(14);
        color: #000;

        &:not(:last-child) {
          margin-right: rem-calc(20);
        }
      }

      &:hover::after {
        content: '';
        display: block;
        position: absolute;
        top: rem-calc(32);
        width: rem-calc(30);
        height: rem-calc(3);
        margin-left: rem-calc(16);
        background-color: #f00;
      }
    }
  }

  .social {
    @include breakpoint(small only) {
      display: flex;
      justify-content: center;
      margin-top: rem-calc(20);
    }

    @include breakpoint(large only) {
      padding-left: rem-calc(30);
    }

    @include breakpoint(xlarge) {
      padding-left: rem-calc(60);
    }

    &_icons {
      display: flex;
      margin: 0;

      li {
        &:not(:last-child) {
          margin-right: rem-calc(14);
        }
      }

      span {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
      }

      &.icon {
        background-repeat: no-repeat;
        background-position: center;
        background-size: rem-calc(33);
        width: rem-calc(33);
        height: rem-calc(33);

        &.vk {
          background-image: url('./img/logo-social/vk.svg');
        }

        &.instagram {
          background-image: url('./img/logo-social/instagram.svg');
        }

        &.facebook {
          background-image: url('./img/logo-social/facebook.svg');
        }

        &.telegram {
          background-image: url('./img/logo-social/vk.svg');
        }
      }
    }
  }

  .copyright {
    font-size: rem-calc(12);

    @include breakpoint(small only) {
      display: flex;
      justify-content: center;
    }

    @include breakpoint(small only, large only) {
      margin-top: rem-calc(20);
    }

    @include breakpoint(medium only) {
      margin-left: rem-calc(35);
      margin-top: rem-calc(10);
    }

    @include breakpoint(large only, xlarge) {
      margin-left: rem-calc(16);
    }

    @include breakpoint(xlarge) {
      margin-top: rem-calc(15);
    }
  }

  .partner {
    display: flex;
    justify-content: flex-end;

    @include breakpoint(small only) {
      align-items: center;
      margin-top: rem-calc(30);
    }

    @include breakpoint(large only) {
      margin-top: rem-calc(6);
    }

    &_text {
      display: flex;
      justify-content: flex-end;
      color: #a4a3a3;
      padding: rem-calc(13 13 13 0);
      font-size: rem-calc(13);
    }

    &_logo {
      display: flex;
      flex-shrink: 1;

      svg {
        padding: rem-calc(10 0 10 10);
        width: rem-calc(100);
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1608");