@use "sass:meta" as ---6r6i8kqj18t;.main {
  h1 {
    font-size: rem-calc(28);
    font-weight: 600;
  }

  h2 {
    font-weight: 700;
    font-size: rem-calc(28);
    line-height: 1.2;
  }

  &.intro {
    width: 100%;
    overflow: hidden;
    position: relative;

    @include breakpoint (small) {
      height: 260px;
    }

    @include breakpoint (medium) {
      height: 440px;
    }

    @include breakpoint (large) {
      height: 390px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include breakpoint (small only) {
        height: 260px;
      }

      @include breakpoint (medium only) {
        height: 440px;
      }
    }

    .intro_block {
      position: absolute;
      background-color: #ff0100;
      padding: rem-calc(30);
      color: #fff;

      @include breakpoint (small only) {
        display: none;
      }

      @include breakpoint(medium only) {
        width: 50%;
        top: 6%;
        right: 0;
        font-size: rem-calc(15);
      }

      @include breakpoint(large) {
        width: rem-calc(630);
        bottom: 36px;
        left: 45vw;
      }
    }
  }

  &.protocols {
    margin-top: rem-calc(90);

    @include breakpoint(small only) {
      margin-top: 3rem;
      margin-bottom: rem-calc(80);
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: rem-calc(20);
      transition: all .2s ease 0s;

      @include breakpoint(small) {
        margin-top: rem-calc(50);
      }

      &:hover {
        background: #f7f7f7;
        border-radius: rem-calc(20);
        padding: rem-calc(20);
        box-shadow: 0 3px 7px 0 #3131312e;
        transform: translate(0, -8px);
      }

      .more {
        font-size: rem-calc(14);
        margin-top: rem-calc(20);
      }

      .logo {
        min-height: rem-calc(80);
        display: flex;
        align-items: center;
      }
    }
  }

  &.solution {
    margin-top: rem-calc(100);

    .wrap {
      padding: rem-calc(0 20 0 10);

      ul {
        color: #989898;

        @include breakpoint(small only) {
          margin-top: rem-calc(40);
          margin-bottom: rem-calc(40);
        }

        @include breakpoint(medium) {
          margin-top: rem-calc(180);
        }

        li {
          display: flex;
          align-items: center;
          position: relative;
          padding-left: rem-calc(30);
          font-size: rem-calc(18);

          &::before {
            content: '';
            display: flex;
            width: rem-calc(8);
            height: rem-calc(45);
            background-color: red;
            position: absolute;
            left: 0;
          }

          a {
            transition: 1s;
            color: #989898;

            &:hover {
              text-decoration: none;
              color: #f00;
              font-weight: 500;
            }
          }

          &:first-child {
            &::before {
              border-radius: rem-calc(5 5 0 0);
            }
          }

          &:last-child {
            &::before {
              border-radius: rem-calc(0 0 5 5);
            }
          }

          &:not(:first-child) {
            margin: rem-calc(12 0);
          }

          &:nth-child(2) {
            line-height: 1;

            span {
              font-size: rem-calc(11);
            }
          }
        }
      }
    }

    &_items {
      right: 0;
      overflow: hidden;

      @include breakpoint(medium) {
        position: absolute;
      }

      .swiper {
        &-slide {
          width: 260px;

          .solution {
            border-radius: rem-calc(20);
            padding: rem-calc(20);
            height: rem-calc(450);
            width: rem-calc(260);
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            justify-content: space-between;
            box-sizing: border-box;

            &::before {
              content: '';
              display: block;
              position: absolute;
              inset: 0;
              opacity: .25;
              border-radius: rem-calc(20);
              transition: all 2s ease;
            }

            &:hover::before {
              content: '';
              display: block;
              position: absolute;
              inset: 0;
              background-color: #fff;
              opacity: .01;
              border-radius: rem-calc(20);
            }

            &.item-1 {
              background-image: url('../img/solution/item/interior_lighting.jpg');
            }

            &.item-2 {
              background-image: url('../img/solution/item/office_lighting.jpg');
            }

            &.item-3 {
              background-image: url('../img/solution/item/commercial_lighting.jpg');
            }

            &.item-4 {
              background-image: url('../img/solution/item/industrial_lighting.jpg');
            }

            &.item-5 {
              background-image: url('../img/solution/item/architectural_lighting.jpg');
              background-position-x: 70%;
            }

            &.item-6 {
              background-image: url('../img/solution/item/street_lighting.jpg');
            }

            .title {
              margin-top: rem-calc(290);
              z-index: 1;
              font-weight: 700;
              font-size: rem-calc(22);
              line-height: 1.2;

              .subtitle {
                font-size: rem-calc(12);
                font-weight: 300;
              }
            }

            .more {
              background-color: #fff;
              color: #000;
              padding: rem-calc(4 4 4 24);
              border-radius: rem-calc(20);
              display: flex;
              align-items: center;
              z-index: 1;
              font-size: rem-calc(13);
              transition: 2s ease-in-out;
              border: 1px solid #fff;

              &::after {
                content: '';
                background-image: url('../img/svg/elements/more-icon.svg');
                display: block;
                width: rem-calc(25);
                background-repeat: no-repeat;
                height: rem-calc(25);
                margin-left: rem-calc(10);
              }
            }

            &:hover .more {
              background-color: transparent;
              border: 1px solid #fff;
              color: #fff;
            }
          }
        }

        &-navigation {
          display: flex;
          position: relative;
          margin: 1rem 0;

          .button-prev {
            display: flex;
            align-items: center;
            background-image: url('../img/svg/elements/arrow-prev.svg');
            background-repeat: no-repeat;
            width: 100px;
            height: 22px;
            cursor: pointer;

            &::after {
              content: '';
              display: block;
              width: 72px;
              height: 4px;
              border-radius: 5px;
              background-color: #888787;
              margin-left: 20px;
            }
          }

          .button-next {
            background-image: url('../img/svg/elements/arrow-next.svg');
            transform: scale(-1, 1);
            background-repeat: no-repeat;
            width: 12px;
            height: 22px;
            cursor: pointer;
          }
        }
      }
    }
  }

  &.news {
    margin-top: rem-calc(50);
    padding: rem-calc(50 0);
    background-color: #f7f7f7;

    .list {
      @include breakpoint(medium only) {
        justify-content: space-between;
      }

      @include breakpoint(xlarge) {
        padding-right: rem-calc(20);
      }

      .news-item {
        border-radius: rem-calc(20);
        margin-top: rem-calc(20);
        background-color: #fff;
        border: 1px solid #efefef;
        transition: all .5s ease-in-out;
        justify-content: space-between;
        display: flex;
        flex-direction: column;

        &:nth-child(n+13) {
          display: none;
        }

        @include breakpoint(medium) {
          width: 49%;
        }

        @include breakpoint(large) {
          width: 32%;

          &:nth-child(3n + 2) {
            margin-left: rem-calc(15);
            margin-right: rem-calc(15);
          }
        }

        @include breakpoint(xlarge) {
          width: 32%;
        }

        &:hover {
          transform: scale(1.02);
          box-shadow: 0 3px 7px 0 #3131312e;
          border: 1px solid #d8cbcb;

          h3 {
            color: #f00;
          }
        }

        img {
          height: rem-calc(200);
          width: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: rem-calc(20 20 0 0);
          border-bottom: 1px solid #f9f9f9;
        }

        .intro {
          padding: rem-calc(15 20);

          h3 {
            font-weight: 700;
            line-height: 1.3;
            font-size: 1rem;
            transition: 1s;
          }

          .text {
            margin-top: rem-calc(15);
            font-size: rem-calc(13);
            height: rem-calc(60);
            overflow: hidden;
            color: #a7a7a7;
          }

          .date {
            margin-top: 1rem;
            font-weight: 600;
            font-size: rem-calc(13);
          }
        }
      }
    }

    .more-news {
      @include breakpoint(large) {
        padding-right: rem-calc(20);
      }
    }
  }

  &.recomendation {
    @include breakpoint(large down) {
      margin-top: rem-calc(20);
    }

    @include breakpoint(xlarge) {
      border-left: 1px solid #d7d7d7;
      padding-left: rem-calc(20);
    }

    .banner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .list {
      background-color: #fff;
      overflow-y: hidden;
      border-radius: rem-calc(20);
      padding: rem-calc(25 20);
      border: 1px solid #efefef;

      @include breakpoint(small only, xlarge) {
        margin-top: rem-calc(20);
      }

      .title {
        font-weight: 700;
        font-size: rem-calc(21);
      }

      .item {
        font-weight: 500;
        font-size: rem-calc(13);

        @include breakpoint(large down) {
          margin-top: rem-calc(20);
          padding-top: rem-calc(15);
        }

        @include breakpoint(xlarge) {
          margin-top: rem-calc(10);
          padding-top: rem-calc(10);
        }

        &:hover {
          >a {
            color: #f00;
            transition: .5s;
          }
        }

        &:not(:nth-child(2)) {
          border-top: 1px solid #d7d7d7;
        }
      }

      .author {
        display: flex;
        font-weight: 700;
        font-size: rem-calc(12);
        margin-top: rem-calc(10);

        img, svg  {
          width: rem-calc(18);
          height: rem-calc(18);
          object-fit: contain;
          margin: rem-calc(0 7);
        }
      }
    }
  }

  &.article {
    margin-top: rem-calc(50);

    .article_items {
      margin-top: 1rem;

      .item {
        display: flex;
        align-items: center;
        font-weight: 700;
        line-height: 1.3;
        overflow: hidden;
        cursor: pointer;

        @include breakpoint(medium down) {
          font-size: 1rem;
        }

        @include breakpoint(large) {
          font-size: 1.3rem;
        }

        img {
          object-fit: cover;
          border-radius: 1.25rem;
          border: 1px solid #d7d7d7;
          transition: all .5s ease-in-out;

          @include breakpoint(medium down) {
            height: 100px;
            width: 130px;
            min-width: 130px;
          }

          @include breakpoint(large) {
            height: 140px;
            width: 210px;
            min-width: 210px;
          }
        }

        .title {
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: all .5s ease-in-out;
          cursor: pointer;

          @include breakpoint(medium down) {
            margin-left: 1rem;
          }

          @include breakpoint(large) {
            margin-left: 1.5rem;
          }
        }

        &:hover {
          .title {
            color: #f00;
            text-shadow: #fff 0 1px 3px;
          }

          img {
            transform: scale(1.02);
          }
        }
      }
    }
  }

  &.education {
    margin-top: rem-calc(100);

    .events__items {
      margin-bottom: 2rem;

      .item {
        display: flex;
        gap: 2rem;

        &__date {
          flex-shrink: 0;
          width: 100px;
          color: #999898;

          .date {
            font-size: 18px;
            font-weight: 600;
            color: red;

            &::after {
              content: '';
              display: block;
              width: 50%;
              height: 0.3rem;
              background-color: #999898;
              border-radius: 0.3125rem;
              margin-top: 0.4rem;
              margin-bottom: 0.4rem;
            }
          }

          .time {
            font-size: 14px;
          }
        }

        &__info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100px;

          @include breakpoint(small only) {
            height: 150px;
          }

          .title {
            font-weight: 500;
            line-height: 1.3;
            font-size: 1.1rem;

            @include breakpoint(small only) {
              font-size: 1rem;
            }


            &:hover {
              a {
                transition: .5s;
                color: red;
              }
            }
          }

          .other {
            display: flex;
            font-size: 13px;
            gap: 2rem;

            a {
              color: #989898;
            }
          }
        }
      }
    }

    .owl-carousel-event {
      overflow: hidden;

      &_wrap {
        display: flex;
      }

      &_container {
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(20);
      }

      &_nav {
        display: none;
      }

      &_dots {
        margin-top: rem-calc(20);
      }
    }

    .item {
      padding-left: rem-calc(5);

      .event {
        border: 1px solid #cacaca;
        border-radius: rem-calc(20);
        transition: all .5s ease-in-out;
        width: rem-calc(270);

        &:hover {
          transform: scale(1.02);

          .intro {
            display: block;
          }

          &.arl {
            background-color: #fff;
          }
        }

        &.arl {
          background-color: #ffdadb;
        }

        &_img {
          position: relative;

          &::before {
            content: '';
            display: block;
            position: absolute;
            border-radius: rem-calc(20);
            background: rgb(0 0 0);
            background: linear-gradient(0deg, #0000004a, #0000002e, #0000);
            inset: 0;
          }

          &.arl-icon {
            &::after {
              content: '';
              background-image: url('../img/svg/arlight-logo-white.svg');
              background-repeat: no-repeat;
              height: rem-calc(30);
              width: rem-calc(120);
              top: rem-calc(15);
              right: rem-calc(10);
              position: absolute;
              opacity: .8;
            }
          }

          img {
            border-radius: rem-calc(20);
            border-bottom: 1px solid #c7c7c7;
          }

          &-title {
            position: absolute;
            bottom: 0;
            padding: rem-calc(30 20 12);

            h3 {
              color: #fff;
              font-weight: bold;
              font-size: rem-calc(20);
              line-height: 1.2;
              text-shadow: 0 0 20px black, 0 0 4px black;
            }

            .intro {
              font-size: rem-calc(12);
              color: #fff;
              text-shadow: 1px 1px 13px black, 1px 1px 5px black;
              display: none;
              transition: 5s ease-in-out;
            }
          }
        }

        &_info {
          display: flex;
          justify-content: space-between;
          font-size: rem-calc(12);
          padding: rem-calc(20);

          &-date {
            color: #888;

            .day {
              display: flex;
              align-items: center;

              &::before {
                content: '';
                display: block;
                background-image: url('../img/svg/elements/date-icon.svg');
                width: rem-calc(18);
                height: rem-calc(18);
                background-repeat: no-repeat;
                margin-right: rem-calc(8);
              }
            }

            .time {
              margin-top: rem-calc(8);
              display: flex;
              align-items: center;

              &::before {
                content: '';
                display: block;
                background-image: url('../img/svg/elements/time-icon.svg');
                width: rem-calc(18);
                height: rem-calc(18);
                background-repeat: no-repeat;
                margin-right: rem-calc(8);
              }
            }
          }

          &-org {
            display: flex;

            img {
              width: rem-calc(18);
              height: rem-calc(18);
              object-fit: contain;
              margin-right: rem-calc(10);
            }
          }
        }
      }
    }

    .edu-arch {
      align-items: center;
      position: relative;
      font-size: rem-calc(15);
      font-weight: 500;
      margin-top: rem-calc(15);

      &::before {
        content: '';
        position: relative;
        background-color: #ff0100;
        border-radius: .625rem;
        border-left: 6px solid #ff0100;
        margin-right: rem-calc(10);
      }

      ul {
        @include breakpoint(small only) {
          display: block;
        }

        @include breakpoint(medium) {
          display: inline-block;
          font-weight: 400;
        }

        li {
          @include breakpoint(small only) {
            margin-top: rem-calc(10);
            margin-left: rem-calc(20);
          }

          @include breakpoint(medium) {
            display: inherit;
            padding-left: rem-calc(20);
          }

          a {
            color: #989898;
            transition: 1s;

            &:hover {
              font-weight: 500;
              color: #ff0100;
            }
          }
        }
      }
    }
  }

  &.partners {
    margin-top: rem-calc(100);

    .logo {
      margin-top: rem-calc(15);

      a {
        width: 100%;

        .item {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1763");