@use "sass:meta" as ---6r6i8kqj18t;header {
  .title-bar {
    background-color: #fff;
    margin: rem-calc(10 0);

    @include breakpoint(small only) {
      justify-content: space-between;
    }

    &_logo {
      width: rem-calc(130);
    }

    &_menu {
      width: rem-calc(30);

      @include breakpoint(small only) {
        margin-top: rem-calc(10);
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1686");