@use "sass:meta" as ---6r6i8kqj18t;.solution {
  &_title {
    font-weight: 600;
    font-size: rem-calc(28);
    line-height: 1.2;
    margin: rem-calc(0 0 20);
    color: #424242;

    span {
      display: block;
      color: #0ba28b;
      font-weight: 300;
      font-size: rem-calc(24);
    }
  }

  &-typical {
    margin-top: rem-calc(50);

    &_item {
      display: flex;
      align-items: center;

      @include breakpoint(small only) {
        flex-direction: column;
      }

      &.disabled {
        opacity: .5;
        filter: grayscale(80%) blur(2px);
      }

      &:first-child {
        margin-top: rem-calc(20);
      }

      &:not(:first-child) {
        @include breakpoint(small only) {
          margin-top: rem-calc(50);
        }

        @include breakpoint(medium) {
          margin-top: rem-calc(35);
        }
      }

      figure {
        @include breakpoint(medium) {
          margin-right: rem-calc(25);
        }

        img {
          border-radius: rem-calc(20);
          width: rem-calc(370);
        }
      }
    }

    &_desc {
      .sub-title {
        color: #424242;
        font-weight: 500;
        font-size: rem-calc(20);
        margin: rem-calc(0 0 20 0);

        @include breakpoint(small only) {
          margin: rem-calc(30 0 20 0);
        }
      }

      .btn-wrap {
        display: flex;
        align-items: center;
      }
    }

    &_author {
      margin-left: rem-calc(20);
    }
  }

  &-realised {
    margin-top: rem-calc(100);
    padding: rem-calc(50 0);
    background-color: #f7f7f7;

    img {
      height: rem-calc(200);
      width: 100%;
      border-radius: rem-calc(20 20 0 0);
    }

    &_item {
      position: relative;
      border-radius: rem-calc(20);
      margin-top: rem-calc(20);
      background-color: #fff;
      min-height: rem-calc(350);
      border: 1px solid #efefef;
      transition: all .5s ease-in-out;
      flex-direction: column;
      display: flex;
      justify-content: space-between;

      h3 {
        color: #000;
        font-size: rem-calc(16);
        margin: rem-calc(10 0 0 0);
      }

      &:hover {
        transform: scale(1.02);
        box-shadow: 0 3px 7px 0 #3131312e;
        border: 1px solid #d8cbcb;

        h3 {
          color: #f00;
        }
      }

      .info {
        padding: rem-calc(15 20);

        .text {
          margin-top: rem-calc(15);
          font-size: rem-calc(13);
          height: rem-calc(60);
          overflow: hidden;
          color: #a7a7a7;
        }

        .author {
          margin-top: rem-calc(15);
        }
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1829");