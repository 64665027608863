@use "sass:meta" as ---6r6i8kqj18t;.page-list {
  .item {
    border: 1px solid #efefef;
    border-radius: 0.625rem;
    overflow: hidden;
    transition: background-color, box-shadow, transform .5s ease-in-out;

    &:hover {
      transform: scale(1.02);
      box-shadow: 0 9px 7px 0 #bbbbbb2e;
      background-color: #fff;
      border: 1px solid #efefef;

      .info .title {
        color: #f00;
      }
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .info {
      padding: 1rem;

      .title {
        font-weight: 500;
        line-height: 1.3;
        font-size: 1.1rem;
        transition: 1s;
        color: #424242;
        margin: 0;
      }

      .date {
        font-size: rem-calc(12);
        margin-bottom: 0.4rem;
      }

      .tizer {
        font-size: rem-calc(13);
        margin-top: 1rem;
      }
    }
  }
}

;@include ---6r6i8kqj18t.load-css("sass-embedded-legacy-load-done:1880");